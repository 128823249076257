@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');
@import url('https://fonts.googleapis.com/css?family=Sofia');

* {
	box-sizing: border-box;
}

.login-body {
	// background: #bdd5e6;
	background-image: url('./notesimg.png');
	background-repeat: no-repeat;
	background-size: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	min-height: 100vh;
	z-index: 990;
}

.base-container {
  background-image: url('./notesimg.png');
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	.header {
		margin-top: 7%;
		color: #070707;
		font-weight: bold;
		font-size: 24px;
		font-family: 'Sofia', sans-serif;
	}

	.content {
		display: flex;
		flex-direction: column;
		background-color: #ffffff;

		.image {
			width: 21em;
			img {
				width: 100%;
				height: 100%;
			}
		}

		.form {
			margin-top: 2em;
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: #ffffff;
			padding: 0 50px;
			height: 100%;
			text-align: center;

			.form-group {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: fit-content;
				label {
					font-size: 20px;
				}
				input {
					margin-top: 6px;
					min-width: 18em;
					height: 37px;
					padding: 0px 10px;
					font-size: 16px;
					font-family: 'Open Sans', sans-serif;
					background-color: #f3f3f3;
					border: 0;
					border-radius: 4px;
					margin-bottom: 31px;
					transition: all 250ms ease-in-out;
					&:focus {
						outline: none;
						box-shadow: 0px 0px 12px 0.8px #3474dbb2;
					}
				}
			}
		}
	}
}
.footer {
	margin-top: 3em;
	//   background-image: url("backgroundimg.jpg")
}

.login-body h1 {
	font-weight: bold;
	margin: 0;
	font-size: 20px;
	text-align: center;
}
.login-body p {
	font-size: 10px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.message {
	color: #070707;
	font-family: 'Sofia', sans-serif;
	text-shadow: 3px 3px 3px #ababab;
}

//Custom Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  background-color: #F26627;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


//Login Main Container
.login {
  width: 27em;
  height: 40em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  position: relative;
  z-index: 99;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #9BD7D1;
    box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.2);
    border-radius: 4px;
    position: relative;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
    padding: 17px 10px;
  }
  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90%;
    background-color: #325D79;
    width: 100%;
    position: absolute;
    right: -34%;
    border-radius: 6px;
    z-index: 1;
    transition: all 400ms ease-in-out;
    cursor: pointer;
    box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.281);

    &.right {
      right: -40%;
      align-items: flex-end;
      &:hover {
        right: -45%;
      }
    }
    &.left {
      right: 40%;
      align-items: flex-start;
      &:hover {
        right: 45%;
      }
    }

    .text {
      font-size: 21px;
      font-weight: 500;
      color: #fff;
      margin-right: 3em;
      margin-left: 3em;
    }
  }
}

//Button
.btn {
  font-size: 21px;
  padding: 5px 20px;
  border: 0;
  background-color: #F26627;
  color: #fff;
  border-radius: 3px;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: #2386c8;
  }
  &:focus {
    outline: none;
  }
}

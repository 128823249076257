/*------------------------------------
Global Styles
-----------------------------------------*/
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p {
  font-size: 12pt;
}
a {
  text-decoration: none;
  color:rgb(9, 71, 100);
}

a:hover {
  color: #f26627;
  text-decoration: none;
}

img {
  object-fit: cover;
}

.underline {
  margin: 8px 0px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  height: 2px;
}


/*-------------------------------------------
Icon ans Spinner styles
----------------------------------------------*/
.icon {
  font-size: 1.9em;
  color: #f26627;
}

@media (prefers-reduced-motion: no-preference) {
  .spin-it {
    animation: spinner infinite 1s linear;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*-------------------------------------------------
Add Note styles
--------------------------------------------------*/

/*------------------------------------------------
View note styles
---------------------------------------------------*/
.note-list{
 position: relative;
}

.note-title{
  font-weight: 600;
}
.note-avatar{
  height: 40px;
  width: 40px;
  background-color: #ddd;
  border-radius: 50%;
}

.media-body{
  line-height: 0.2;
}

#scrollToTop{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #077784;
  color: #fcfcfc;
  position: fixed;
  bottom: 25px;
  right: 30px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  cursor: pointer;
}

.note{
  background: #eeeeee;
}


/*------------------------------------------------
Medium screen
---------------------------------------------------*/
@media all and (max-width: 991px) {
  .note p{
    font-size: calc(12px + 1vmin);
  }
}

/*------------------------------------------------
Mobile screen
---------------------------------------------------*/

@media all and (max-width: 640px) {
  .note-title{
    font-weight: 600;
    text-align: center;
  }
  .note p{
    font-size: calc(12px + 1vmin);
  }


}
/*---------------------------------------------
Responsive for large screens
-------------------------------------------------*/
@media only screen and (min-width: 991px) {
  
}

/*Landing page*/
.bg-banner {
	background-image: url('../../images/notes.jpeg');
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100vh;
	margin-top: -30px;
}

.land-container {
	position: relative;
	top: 20%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.btn-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.btn-c {
	background-color: #efeeee;
	color: #f26627;
	transition: all 0.5s;
	border: 2px solid #f26627;
	font-family: 'Viaoda';
	font-weight: 300;
	font-size: 1.4em;
	padding-left: 40px;
	padding-right: 40px;
}
.btn-c:hover {
	background-color: #f26627;
	color: #efeeee;
	padding: 50px;
	border: 2px solid #f26627;
	box-shadow: 3px 5px 5px white;
}

.btn-cf {
	background-color: #f26627;
	color: #efeeee;
	transition: all 0.5s;
	border: 2px solid #f26627;
	font-family: 'Libre';
	font-size: 1.4em;
	font-weight: 200;
}
.btn-cf:hover {
	background-color: #efeeee;
	color: #f26627;
	border: 2px solid #f26627;
}
.land-intro {
	margin-top: 5rem;
	font-family: 'Roboto', cursive;
	font-size: 45px;
	font-weight: 600;
	text-align: center;
	color: #efeeee;
}

.land-paragraph{
	text-align: center;
	font-size: 1.5em;
	font-weight: 800;
	color: #efeeee;
	font-family: 'Roboto', cursive;
	margin: 20px;
	text-transform: capitalize;
}

/*------------------------------------------------
Medium screen
---------------------------------------------------*/
@media all and (max-width: 991px) {
}

/*------------------------------------------------
Mobile screen
---------------------------------------------------*/

@media all and (max-width: 640px) {
}

.container{
    position: relative;
    max-width: 1100px;
    margin: 0px auto;
    width: 100%;
}

.note-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: flex-start; 
}

.note{
    height: 100%;
    position: relative;
    width: 100%;
    text-align: left;
    background-color: white;
}  

.note-side{
    position: relative;
    width: 100%;
    background-color: white;
}

.title{
   font-size: 15pt;
    font-weight: 700;

}


.see-more {
    position: relative;
}

.btn {
    background-color: #c3d8f5;
    font-size: inherit;
    font-family: inherit;
	cursor: pointer;
    transition: all 0.3s;
	border: none;
}

.btn:hover{
	box-shadow: 3px 5px 5px rgb(27, 63, 63);
	transform: translate(-2%, -2%);
    
}

#edit-note-title{
    text-transform: uppercase;
    text-align: center;
    padding-top: 20px;
}

.edit-form-container{
    background-color: #edf3f3;
}

#editform{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 300px;
    width: 100%;
    padding: 15px;
}

#editform > .edit-note-fields{
 margin-bottom: 10px;
 width: 100%;
 padding: 15px;
 outline: none;
 border: 1px solid #c3d8f5;
 border-radius: 10px;
}

#editform > textarea{
    min-height: 200px;
}


@media all and (max-width: 700px) {
	.container{
        margin: 0px 20px;
        width: auto;
    }

    .container h2{
        margin-top: 7rem;
    }
}



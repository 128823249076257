.about {
	position: relative;
	height: 100%;
	width: 100%;
	font-weight: 300;
	margin: 0px;
	box-sizing: border-box;
	text-align: center;
}

.about-container {
	position: relative;
	min-height: 70vh;
	background: url('../../images/home-background.jpg');
	background-position: bottom left;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	flex-direction: column;
	color: #ffeeee;
	z-index: 990;
}

.overlay {
	height: 100%;
	width: 100%;
	z-index: 991;
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;
}

.about-content {
	justify-self: flex-end;
	height: 100%;
	min-height: 300px;
	width: 40%;
	z-index: 992;
	padding: 20px;
}

.about h2 {
	font-size: 2em;
	font-weight: 600;
	padding: 0px 30px;
}

.about p {
	font-size: 12pt;
	padding: 0px 30px;
}

.about-btn {
	position: relative;
	padding: 0px 30px;
	padding-bottom: 20px;
}

.sub-about {
	text-align: center;
}

/*------------------------------------------------
Mobile screen
---------------------------------------------------*/

@media all and (max-width: 700px) {
	.about-container {
		min-height: 100vh;
		justify-content: center;
		align-items: flex-start;
	}
	.about-content {
		top: 100px;
		position: relative;
		justify-self: flex-start;
		text-align: center;
		width: 100%;
		height: 100%;
		z-index: 992;
		padding: 20px;
	}
}
